/*body {
  display: flex;
  justify-content: center;
  padding-top: 50px;
}

.frontpage-job {
  color: #f77754;
}
*/
.menu {
  display: flex;
  justify-content: center;
  background: #6B9362;
}

.menu-button {
  color: white;
  text-decoration: none;
  font-size: 20px;
}

.icon {
  /* marginRight: theme.spacing(2) */
  margin-right: 20px;
}
.heroContent {
  /* backgroundColor: theme.palette.background.paper
  padding: theme.spacing(8, 0, 6) */
  background-color: #6B9362;
  padding: 60px 0px;
}

.logo {
  width: 100%;
  padding-bottom: 30px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.main-btn {
  color: #ffffff;
  font-size: 20px;
  background-color: #6B9362;
  border-radius: 3px;
  padding: 10px 15px;
}

.main-btn:focus {
  outline: 0;
}

.heroButtons {
  /* marginTop: theme.spacing(4) */
  margin-top: 40px;
}
.cardGrid {
  /* paddingTop: theme.spacing(8)
  paddingBottom: theme.spacing(8) */

  padding-top: 60px;
  padding-bottom: 60px;
}
.card {
  /* height: '100%'
  display: 'flex',
  flexDirection: 'column', */
  height: 100%;
  display: flex;
  flex-direction: column;
}
.cardMedia {
  /* paddingTop: '56.25%', // 16:9 */
  padding-top: 56.25%;
}
.cardContent {
  /* flexGrow: 1 */
  flex-grow: 1;
}
.footer {
  /* backgroundColor: theme.palette.background.paper
  padding: theme.spacing(6) */
  background-color: #6B9362;
  padding: 60px
}

.modal-wrapper {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.60);
  display: flex;
  justify-content: center;
  padding: 50px 20px;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

.modal, .no-modal-wrapper {
  width: 100%;
  height: 400px;
  max-width: 700px;
  background-color: #444444;
  color: #ffffff;
  position: relative;
  border: 5px;
  border-radius: 10px;
  margin: auto;
}

.modal {
  box-shadow: 2px 4px 20px 0px rgba(0, 0, 0, 0.75);
}

.no-modal-wrapper {
  margin-top: 10vh;
}

.modal-image {
  background-color: #000000;
  position: relative;
  width: 45%;
  height: 100%;
  float: left;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.business-image {
  position: relative;
  width: 100%;
  height: 100%;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  opacity: 0.4;
}

.modal-content {
  position: relative;
  width: 55%;
  height: 100%;
  float: right;
}

.modal-content-wrapper {
  margin: 30px 30px;
}

.modal-header {
  margin-bottom: 30px;
}

.business-name, .business-address {
  white-space: nowrap;
  overflow: scroll;
}

.modal h1, .no-modal-wrapper h1 {
  font-size: 30px;
  margin-bottom: -15px;
}

.modal p, .no-modal-wrapper p {
  font-size: 16px;
}

.modal-instructions {
  margin-bottom: 30px;
}

.btn-group {
  margin-top: 30px;
}

.buy-btn {
  background-color: #6B9362;
  color: #ffffff;
  font-size: 18px;
  border: none;
  border-radius: 5px;
  width: 120px;
  height: 50px;
  margin-right: 20px;
}

.icon-group a {
  color: #ffffff;
}

.twitter:hover {
  color: #00acee;
}

.facebook:hover {
  color: #1778f2;
}

.mail:hover {
  color: #e95950;
}

.share-icon {
  width: 50px;
  height: 30px;
}

.faq-wrapper {
  width: 60%;
  margin: 10vh auto;
}

.share-icon-group {
  width: 60%;
  margin: 10vh auto;
  text-align: center;
  color: #444444;
}

.business-title-link {
  text-decoration: none;
  color: #ffffff;
}

.business-title-link:hover {
  color: #6B9362;
}

@media only screen and (max-width: 670px) {
  .modal, .no-modal-wrapper {
    height: 80vh;
  }

  .modal-image {
    width: 0%;
    height: 0%;
    visibility: hidden;
  }
  .modal-content {
    float: none;
    margin: 0% auto;
  }
}

@media only screen and (max-width: 450px) {

  .modal-content {
    width: 70%;
    margin-top: 10%;
  }
}

@media only screen and (max-width: 370px) {
  .modal-content {
    width: 100%;
  }


}
